.MuiTableSortLabel-icon {
  opacity: 1;
  // makes the icon visible
}
.MuiTableContainer-root {
  height: calc(100vh - 132px);
}
// add media query for mobile
@media (max-width: 1517px) {
  .MuiTableContainer-root {
    height: calc(100vh - 30px);
  }
}
.normalCell {
  padding: 12px 16px;
}

.shrink-label {
  font-size: 17px !important; /* Adjust the font size as needed */
}

/* Rainbow Tag Styles */

#rainbow {
  background: linear-gradient(
    45deg,
    red,
    orange,
    yellow,
    green,
    blue,
    indigo,
    violet,
    red
  );
}

/* Gender Tag Styles */

#gender {
  background: linear-gradient(45deg, blue, violet);
}

/* Customize website's scrollbar */

::-webkit-scrollbar {
  background-color: #fff;
  width: 19px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 6px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 5px solid #f4f4f4;
}
